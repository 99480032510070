const getInitialThemePalette = require('../utils/getInitialThemePalette')
const getInitialThemeProperties = require('../utils/getInitialThemeProperties')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')

// WARNING: DO NOT CHANGE THIS ID. Can be present in userdata
// Must be a valid ObjectID
const DEFAULT_THEME_ID = '5be2f7db31852084f4491812'
const themeEnumId = '__BUILTIN_ENUM__THEME'
const themeAppVarPropertyId = '__APP_VAR__THEME'

const defaultThemeFont = {
	family: 'Roboto',
	type: 'google_fonts',
	variants: ['300', '300italic', 'regular', 'italic', '500', '500italic'],
}

const createEnumeratedTypeFromThemes = (themes) => {
	const values = [
		{
			id: DEFAULT_THEME_ID,
			name: 'Default',
			value: DEFAULT_THEME_ID,
		},
	].concat(
		themes.map((item) => ({
			id: item.id,
			name: item.name,
			value: item.id,
		}))
	)

	return {
		id: themeEnumId,
		name: 'Theme',
		icon: 'mdi mdi-palette-advanced',
		description: 'All Available Themes',
		dataType: e_EnumeratedTypeDataType.AUTO,
		hideFromDataModelView: true, // Hide from DataModel
		isBuiltIn: true,
		values: values,
	}
}

const builtInThemeDefinition = {
	getDefaultTheme: () => ({
		id: DEFAULT_THEME_ID,
		name: 'Default',
		palette: {
			...getInitialThemePalette(),
			primary: {
				light: '#64B5F6',
				main: '#2196F3',
				dark: '#1976D2',
			},
			secondary: {
				light: '#FFD54F',
				main: '#FFC107',
				dark: '#FFA000',
			},
		},
		themeFonts: [defaultThemeFont],
		themeProperties: {
			...getInitialThemeProperties(true),
		},
		cssUrls: ['https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;1,300;1,500'],
		readOnly: true,
	}),
	defaultThemeId: DEFAULT_THEME_ID,
	DEFAULT_THEME_ID,
	themeEnumId,
	createEnumeratedTypeFromThemes,
	themeAppVarPropertyId,
}

module.exports = builtInThemeDefinition
