import browserHistory from '../../components/browserHistory'
import {
	getNormalViews,
	getActiveAppReadableId,
	getMainDrawer,
	getDefaultViewPath,
} from 'selectors/metadataSelectors'
import { setViewTransition } from '../runtimeStateActions'

import isNil from 'lodash/isNil'

import { EXTRA_SMALL, SMALL } from 'enums/e_ScreenSize'
import { e_DrawerType } from 'enums/e_PropertyTypes'
import e_SwitchTransitionType from 'enums/e_SwitchTransitionType'

const p_navigate = async ({
	actionNode,
	contextData,
	dispatch,
	getState,
	appController,
	actionNodeLogger,
}) => {
	const state = getState()

	// Close drawer if open and temporary
	const appVariablesDataSource = appController.getAppVariablesDataSource()
	const currentDrawerState = appVariablesDataSource.getDrawerState()

	if (currentDrawerState) {
		const screenSize = appVariablesDataSource.getClientScreenSize()
		const drawer = getMainDrawer(state)
		if (
			drawer &&
			(drawer.drawerType === e_DrawerType.TEMPORARY ||
				((!drawer.drawerType || drawer.drawerType === e_DrawerType.RESPONSIVE) &&
					[EXTRA_SMALL, SMALL].includes(screenSize)))
		)
			appVariablesDataSource.setDrawerState(false)
	}

	dispatch(setViewTransition(actionNode.transitionType || e_SwitchTransitionType.NONE))

	const views = getNormalViews(state)
	const viewId = actionNode.viewId && appController.getDataFromDataValue(actionNode.viewId, contextData)
	const view = viewId && views.find((view) => view.id === viewId)

	const subViewId =
		actionNode.subViewId && appController.getDataFromDataValue(actionNode.subViewId, contextData)
	const subView = subViewId && views.find((view) => view.id === subViewId)

	if (actionNode.viewId && !view) throw new Error('Navigate - Could not find specified view') // TODO: do best effort instead?
	if (actionNode.subViewId && !subView) throw new Error('Navigate - Could not find specified subview') // TODO: do best effort instead?

	const identView = view && (view.path || view.id)
	const identSubView = subView && (subView.path || subView.id)
	let urlParams = ''
	if (actionNode.urlParameters?.length > 0) {
		urlParams = actionNode.urlParameters
			.filter((item) => !isNil(item.value))
			.map((item) => {
				const value = appController.getDataFromDataValue(item.value, contextData)
				if (isNil(value)) return undefined
				return item.nodeName + '=' + value
			})
			.filter(Boolean)
			.join('&')

		const urlParamsDataSource = appController.getUrlParamsDataSource()
		await urlParamsDataSource.p_updateOwnDataFromLocation('?' + urlParams)
	}

	const hasNoNewPath = !identView && !identSubView && !urlParams
	if (hasNoNewPath && actionNode.navigateBack) {
		actionNodeLogger.debug('Go Back')
		browserHistory.goBack()
		return
	}

	const { pathname, search } = browserHistory.location
	const appId = getActiveAppReadableId(state)

	const shouldReplaceHistory = actionNode.navigateBack || !view
	let newPath = ''

	if (view && subView) {
		newPath = `/${appId}/${identView}/${identSubView}`
	} else if (subView) {
		const paths = pathname.split('/')
		let viewPath = paths[2]

		if (!viewPath) viewPath = getDefaultViewPath(state)

		newPath = `/${appId}/${viewPath}/${identSubView}`
	} else if (view) {
		newPath = `/${appId}/${identView}`
	} else if (actionNode.navigateToRoot) {
		newPath = `/${appId}`
	}

	if (newPath) {
		const urlPathDataSource = appController.getUrlPathDataSource()
		urlPathDataSource.updateOwnDataFromLocation(newPath)
		newPath = urlParams ? `${newPath}?${urlParams}` : newPath
	} else if (urlParams || !!search) {
		newPath = pathname + '?' + urlParams
	}

	if (!newPath) return // nothing to do

	actionNodeLogger.debug(`Navigating to new path (${shouldReplaceHistory ? 'replace' : 'push'}): ${newPath}`)
	if (shouldReplaceHistory) {
		browserHistory.replace(newPath)
	} else {
		//setTimeout is needed to move browserHistory.push() to the end of the queue so that dispatch(setViewTransition) has time to take effect
		setTimeout(() => browserHistory.push(newPath), 1)
	}
}

export default p_navigate
