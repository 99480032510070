/**
 * Supported languages for AutoTranslation
 * Must be available here: https://cloud.google.com/translate/docs/languages
 */

const e_AutoTranslateLanguage = {
	ENGLISH: 'en',
	NORWEGIAN: 'no',
	SWEDISH: 'sv',
	DANISH: 'da',
	POLISH: 'pl',
	GERMAN: 'de',
	FRENCH: 'fr',
	SPANISH: 'es',
	PORTUGUESE: 'pt',
	ITALIAN: 'it',
	ARMENIAN: 'hy',
	LUXEMBOURGISH: 'lb',
	DUTCH: 'nl',

	options: [
		{
			ident: 'en',
			display: 'English',
		},
		{
			ident: 'no',
			display: 'Norsk',
		},
		{
			ident: 'sv',
			display: 'Svenska',
		},
		{
			ident: 'da',
			display: 'Dansk',
		},
		{
			ident: 'pl',
			display: 'Polskie',
		},
		{
			ident: 'de',
			display: 'Deutsche',
		},
		{
			ident: 'fr',
			display: 'Français',
		},
		{
			ident: 'es',
			display: 'Español',
		},
		{
			ident: 'pt',
			display: 'Português',
		},
		{
			ident: 'it',
			display: 'Italiano',
		},
		{
			ident: 'hy',
			display: 'Armenian',
		},
		{
			ident: 'lb',
			display: 'Luxembourgish',
		},
		{
			ident: 'nl',
			display: 'Dutch',
		},
	],
}

module.exports = e_AutoTranslateLanguage
