import React, { Component } from 'react'
import PropTypes from 'prop-types'

import throttle from 'lodash/throttle'
import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Slider from '@material-ui/core/Slider'

import { e_SliderMarksType } from 'enums/e_PropertyTypes'

const styles = {
	root: {
		minWidth: 50,
	},
	rootVertical: {
		display: 'flex',
		height: '100%',
		minHeight: 50,
		width: 'fit-content',
	},
	sliderVertical: {
		minHeight: 50,
		height: 'unset',
	},
}

class UiSlider extends Component {
	constructor(props) {
		super(props)

		this.state = {}

		this.onValueChange = this.onValueChange.bind(this)
		this.updateValue = throttle(this.updateValue.bind(this), 100)
		this.updateSecondValue = throttle(this.updateSecondValue.bind(this), 100)
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let min
		if (!isNil(nextProps.component.min))
			min = nextProps.appController.getDataFromDataValue(nextProps.component.min, nextProps.contextData)

		let max
		if (!isNil(nextProps.component.max))
			max = nextProps.appController.getDataFromDataValue(nextProps.component.max, nextProps.contextData)

		if ((min ?? 0) > (max ?? 100)) {
			console.warn(
				`UI Slider - Invalid Min (${min ?? 0}) and Max (${
					max ?? 100
				}) values. Min value cannot be larger than Max value. Reverting to default 0/100.`
			)
			min = undefined
			max = undefined
		}

		let step
		if (nextProps.component.step)
			step = nextProps.appController.getDataFromDataValue(nextProps.component.step, nextProps.contextData)

		let value
		let secondValue
		let secondValueOwnData
		let localValue

		if (!isNil(nextProps.component.secondValue)) {
			secondValueOwnData = nextProps.appController.getDataFromDataBinding({
				dataBinding: nextProps.component.secondValue,
				contextData: nextProps.contextData,
			})

			if (isPlainObject(nextProps.ownData)) value = nextProps.ownData[nextProps.component.value.nodeName]

			if (isPlainObject(secondValueOwnData))
				secondValue = secondValueOwnData[nextProps.component.secondValue.nodeName]

			localValue = [!isNil(value) ? value : min ?? 0, !isNil(secondValue) ? secondValue : max ?? 100]
		} else {
			if (isPlainObject(nextProps.ownData)) {
				value = nextProps.ownData[nextProps.component.value.nodeName]
			}
			localValue = !isNil(value) ? value : min ?? 0
		}

		let marks
		if (nextProps.component.marksType === e_SliderMarksType.ON) {
			marks = true
		} else if (nextProps.component.marksType === e_SliderMarksType.CUSTOM) {
			const customMarks = nextProps.component.customMarks
				.filter((mark) => {
					return (
						isNil(mark.visible) ||
						nextProps.appController.getDataFromDataValue(mark.visible, nextProps.contextData)
					)
				})
				.map((mark) => {
					return {
						value: nextProps.appController.getDataFromDataValue(mark.value, nextProps.contextData),
						label: nextProps.appController.getDataFromDataValue(mark.label, nextProps.contextData),
					}
				})

			marks = JSON.stringify(prevState.marks) === JSON.stringify(customMarks) ? prevState.marks : customMarks

			if (nextProps.component.restrictToMarks) step = null
		}

		if (
			value === prevState.value &&
			secondValue === prevState.secondValue &&
			step === prevState.step &&
			min === prevState.min &&
			max === prevState.max &&
			marks === prevState.marks
		)
			return null // No need to update

		return {
			localValue,
			value,
			secondValue,
			secondValueOwnData,
			step,
			min,
			max,
			marks,
		}
	}

	onValueChange(event, value) {
		if (this.props.readOnly) return

		const preValue = isArray(this.state.localValue) ? this.state.localValue[0] : this.state.localValue
		const preSecondValue = isArray(this.state.localValue) ? this.state.localValue[1] : null

		this.setState({
			localValue: value,
		})

		if (isArray(value)) {
			if (value[0] !== this.state.value) {
				this.updateValue(event, value[0], preValue, preSecondValue)
			} else if (value[1] !== this.state.secondValue) {
				this.updateSecondValue(event, value[1], preValue, preSecondValue)
			}
		} else {
			this.updateValue(event, value, preValue, preSecondValue)
		}
	}

	updateValue(event, value, preValue, preSecondValue) {
		const props = this.props
		const onValueChangeEvent = props.component.onValueChange
			? () =>
				props.eventHandler(
					props.component.onValueChange,
					null,
					{
						eventType: 'onValueChange',
						eventHandlerValues: {
							previousValue: preValue,
							previousSecondValue: preSecondValue,
						},
					},
					event
				)
			: undefined

		if (!props.component.value) return

		this.props.appController.modifySingleValue(
			props.component.value,
			props.ownData,
			value,
			{},
			onValueChangeEvent
		)
	}

	updateSecondValue(event, value, preValue, preSecondValue) {
		const props = this.props
		const onValueChangeEvent = props.component.onValueChange
			? () =>
				props.eventHandler(
					props.component.onValueChange,
					null,
					{
						eventType: 'onValueChange',
						eventHandlerValues: {
							previousValue: preValue,
							previousSecondValue: preSecondValue,
						},
					},
					event
				)
			: undefined

		if (!props.component.secondValue) return

		this.props.appController.modifySingleValue(
			props.component.secondValue,
			this.state.secondValueOwnData,
			value,
			{},
			onValueChangeEvent
		)
	}

	render() {
		const { component, componentId, disabled, styleProp, conditionalClassNames, classes } = this.props
		const { step, min, max, marks } = this.state
		return (
			<div
				className={classNames(
					component.vertical ? classes.rootVertical : classes.root,
					'c' + component.id,
					conditionalClassNames
				)}
				style={styleProp}
			>
				<Slider
					id={componentId}
					classes={{
						root: component.vertical ? classes.sliderVertical : undefined,
					}}
					value={this.state.localValue || 0}
					color={component.sliderColor}
					valueLabelDisplay={component.valueLabelDisplay}
					onChange={this.onValueChange}
					orientation={component.vertical ? 'vertical' : 'horizontal'}
					disabled={disabled}
					min={min}
					max={max}
					step={step}
					marks={marks}
				/>
			</div>
		)
	}
}

UiSlider.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
		modifySingleValue: PropTypes.func.isRequired,
		getDataFromDataBinding: PropTypes.func.isRequired,
	}).isRequired,
	componentId: PropTypes.string.isRequired,
	contextData: PropTypes.object,
	value: PropTypes.number,
	disabled: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool,
	ownData: PropTypes.object,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiSlider)
