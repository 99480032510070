const {
	STRING,
	INTEGER,
	DURATION,
	FLOAT,
	DATE,
	BOOLEAN,
	MULTI_ENUM,
	MULTI_REFERENCE,
} = require('./e_ObjectClassDataType')

const e_ValueProcessorOperation = {
	DATE_ADD: 'date_add',
	DATE_SUBTRACT: 'date_subtract',
	DATE_START_OF: 'date_start_of',
	DATE_END_OF: 'date_end_of',
	DATE_FORMAT: 'date_format',
	NUMBER_FORMAT: 'number_format',
	DURATION_FORMAT: 'duration_format',
	DURATION_ADD: 'duration_add',
	DURATION_SUBTRACT: 'duration_subtract',
	INVERT_VALUE: 'invert_value',
	INTEGER_ADD: 'integer_add',
	FLOAT_ADD: 'float_add',
	INTEGER_SUBTRACT: 'integer_subtract',
	FLOAT_SUBTRACT: 'float_subtract',
	ADD_CONTEXT: 'add_context',
	ADD_SELECTED: 'add_selected',
	ADD_SELECTION: 'add_selection',
	REMOVE_CONTEXT: 'remove_context',
	REMOVE_SELECTED: 'remove_selected',
	REMOVE_SELECTION: 'remove_selection',
	REFERENCE_ADD_CONTEXT: 'reference_add_context',
	REFERENCE_ADD_SELECTED: 'reference_add_selected',
	REFERENCE_ADD_SELECTION: 'reference_add_selection',
	REFERENCE_REMOVE_CONTEXT: 'reference_remove_context',
	REFERENCE_REMOVE_SELECTED: 'reference_remove_selected',
	REFERENCE_REMOVE_SELECTION: 'reference_remove_selection',

	options: [
		{
			ident: 'date_add',
			display: 'Add',
			icon: 'mdi mdi-plus-box-outline',
			inputDataType: [DATE],
			outputDataType: DATE,
		},
		{
			ident: 'date_subtract',
			display: 'Subtract',
			icon: 'mdi mdi-minus-box-outline',
			inputDataType: [DATE],
			outputDataType: DATE,
		},
		{
			ident: 'date_start_of',
			display: 'Start of',
			icon: 'mdi mdi-format-horizontal-align-left',
			inputDataType: [DATE],
			outputDataType: DATE,
		},
		{
			ident: 'date_end_of',
			display: 'End of',
			icon: 'mdi mdi-format-horizontal-align-right',
			inputDataType: [DATE],
			outputDataType: DATE,
		},
		{
			ident: 'date_format',
			display: 'Format',
			icon: 'mdi mdi-format-paint',
			inputDataType: [DATE],
			outputDataType: STRING,
		},
		{
			ident: 'duration_format',
			display: 'Format',
			icon: 'mdi mdi-format-paint',
			inputDataType: [DURATION],
			outputDataType: STRING,
		},
		{
			ident: 'duration_add',
			display: 'Add',
			icon: 'mdi mdi-plus-box-outline',
			inputDataType: [DURATION],
			outputDataType: DURATION,
		},
		{
			ident: 'duration_subtract',
			display: 'Subtract',
			icon: 'mdi mdi-minus-box-outline',
			inputDataType: [DURATION],
			outputDataType: DURATION,
		},
		{
			ident: 'invert_value',
			display: 'Invert Boolean',
			icon: 'mdi mdi-swap-vertical',
			inputDataType: [BOOLEAN],
			outputDataType: BOOLEAN,
		},
		{
			ident: 'float_add',
			display: 'Add',
			icon: 'mdi mdi-plus-box-outline',
			inputDataType: [FLOAT],
			outputDataType: FLOAT,
		},
		{
			ident: 'float_subtract',
			display: 'Subtract',
			icon: 'mdi mdi-minus-box-outline',
			inputDataType: [FLOAT],
			outputDataType: FLOAT,
		},
		{
			ident: 'integer_add',
			display: 'Add',
			icon: 'mdi mdi-plus-box-outline',
			inputDataType: [INTEGER],
			outputDataType: INTEGER,
		},
		{
			ident: 'integer_subtract',
			display: 'Subtract',
			icon: 'mdi mdi-minus-box-outline',
			inputDataType: [INTEGER],
			outputDataType: INTEGER,
		},
		{
			ident: 'number_format',
			display: 'Number Format',
			icon: 'mdi mdi-numeric',
			//inputDataType: [STRING, INTEGER, FLOAT], // Don't think STRING needs to be an input datatype anymore
			inputDataType: [INTEGER, FLOAT],
			outputDataType: STRING,
		},
		{
			ident: 'add_context',
			display: 'Add Context',
			icon: 'mdi mdi-plus',
			inputDataType: [MULTI_ENUM],
			outputDataType: MULTI_ENUM,
		},
		{
			ident: 'add_selected',
			display: 'Add Selected',
			icon: 'mdi mdi-layers-plus',
			inputDataType: [MULTI_ENUM],
			outputDataType: MULTI_ENUM,
		},
		{
			ident: 'add_selection',
			display: 'Add Selection',
			icon: 'mdi mdi-playlist-plus',
			inputDataType: [MULTI_ENUM],
			outputDataType: MULTI_ENUM,
		},
		{
			ident: 'remove_context',
			display: 'Remove Context',
			icon: 'mdi mdi-close',
			inputDataType: [MULTI_ENUM],
			outputDataType: MULTI_ENUM,
		},
		{
			ident: 'remove_selected',
			display: 'Remove Selected',
			icon: 'mdi mdi-layers-remove',
			inputDataType: [MULTI_ENUM],
			outputDataType: MULTI_ENUM,
		},
		{
			ident: 'remove_selection',
			display: 'Remove Selection',
			icon: 'mdi mdi-playlist-remove',
			inputDataType: [MULTI_ENUM],
			outputDataType: MULTI_ENUM,
		},
		{
			ident: 'reference_add_context',
			display: 'Add Context',
			icon: 'mdi mdi-plus',
			inputDataType: [MULTI_REFERENCE],
			outputDataType: MULTI_REFERENCE,
		},
		{
			ident: 'reference_add_selected',
			display: 'Add Selected',
			icon: 'mdi mdi-layers-plus',
			inputDataType: [MULTI_REFERENCE],
			outputDataType: MULTI_REFERENCE,
		},
		{
			ident: 'reference_add_selection',
			display: 'Add Selection',
			icon: 'mdi mdi-playlist-plus',
			inputDataType: [MULTI_REFERENCE],
			outputDataType: MULTI_REFERENCE,
		},
		{
			ident: 'reference_remove_context',
			display: 'Remove Context',
			icon: 'mdi mdi-close',
			inputDataType: [MULTI_REFERENCE],
			outputDataType: MULTI_REFERENCE,
		},
		{
			ident: 'reference_remove_selected',
			display: 'Remove Selected',
			icon: 'mdi mdi-layers-remove',
			inputDataType: [MULTI_REFERENCE],
			outputDataType: MULTI_REFERENCE,
		},
		{
			ident: 'reference_remove_selection',
			display: 'Remove Selection',
			icon: 'mdi mdi-playlist-remove',
			inputDataType: [MULTI_REFERENCE],
			outputDataType: MULTI_REFERENCE,
		},
	],
}

module.exports = e_ValueProcessorOperation
