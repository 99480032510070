import isPlainObject from 'lodash/isPlainObject'
import isBoolean from 'lodash/isBoolean'
import isNumber from 'lodash/isNumber'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'

import { BOOLEAN, MULTI_ENUM, MULTI_REFERENCE } from 'enums/e_ObjectClassDataType'

const getReturnValueFromDataObject = ({
	dataObject,
	dataValue,
	options,
	edgeDataBinding,
	nodeName,
	appController,
	contextData,
}) => {
	if (!isPlainObject(dataObject)) return undefined

	if (edgeDataBinding.enumeratedTypeId && (options.getDisplayValue || options.getIcon)) {
		if (isArray(dataObject[nodeName])) {
			// multiEnum
			const enumValues = dataObject[nodeName]
			const dataSourceId = `${edgeDataBinding.dataSourceId}.${edgeDataBinding.enumeratedTypeId}`

			if (
				contextData &&
				contextData[dataSourceId]?.length &&
				enumValues.includes(contextData[dataSourceId][0]?.value)
			) {
				const enumeratedTypeValue = contextData[dataSourceId][0].value
				if (options.getDisplayValue || options.getIcon) {
					const enumItem = appController.getEnumeratedTypeValue({
						enumeratedTypeId: edgeDataBinding.enumeratedTypeId,
						enumeratedTypeValue,
					})
					if (enumItem) {
						if (options.getDisplayValue) return enumItem.name
						else if (options.getIcon) return enumItem.icon
					} else {
						return enumeratedTypeValue
					}
				} else {
					return enumeratedTypeValue
				}
			}

			if (options.getDisplayValue) {
				const enumNames = enumValues
					.map((enumeratedTypeValue) => {
						const enumItem = appController.getEnumeratedTypeValue({
							enumeratedTypeId: edgeDataBinding.enumeratedTypeId,
							enumeratedTypeValue,
						})
						if (enumItem) return enumItem.name
						else return enumeratedTypeValue
					})
					.join(', ')
				return enumNames
			}
		}

		const enumItem = appController.getEnumeratedTypeValue({
			enumeratedTypeId: edgeDataBinding.enumeratedTypeId,
			enumeratedTypeValue: dataObject[nodeName],
		})

		if (options.getIcon && enumItem) return enumItem.icon

		if (enumItem) return enumItem.name
	}

	let returnValue = dataObject[nodeName]

	if (edgeDataBinding.valueComponentId) {
		returnValue = appController._getValueComponent(returnValue, edgeDataBinding.valueComponentId)
	}

	if (options.getDisplayValue && isNumber(returnValue)) returnValue += ''

	const propertyMetadata = appController.getPropertyMetadata(dataValue.dataBinding)
	if (
		options.getDisplayValue &&
		(isBoolean(returnValue) || (isNil(returnValue) && propertyMetadata?.dataType === BOOLEAN))
	) {
		returnValue = appController.getBooleanDisplayValue(dataValue, returnValue)
	}

	if (options.getDisplayValue && dataValue?.isDateTime) {
		returnValue = appController._getDateDisplayValue(returnValue)
	}

	if ([MULTI_REFERENCE, MULTI_ENUM].includes(propertyMetadata?.dataType) && isArray(returnValue)) {
		const dataSourceId = appController.getDataSourceIdFromDataBindingProperty(dataValue.dataBinding)
		const contextObjects = contextData[dataSourceId]
		if (contextObjects?.length) {
			const contextObjectId = contextObjects[0]._id
			if (returnValue.includes(contextObjectId)) return contextObjectId
		}
	}

	return returnValue
}

export default getReturnValueFromDataObject
