import moment from 'moment'
import { Settings as luxonSettings } from 'luxon'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import {
	en as enPlurals,
	no as noPlurals,
	da as daPlurals,
	de as dePlurals,
	es as esPlurals,
	fr as frPlurals,
	hy as hyPlurals,
	it as itPlurals,
	lb as lbPlurals,
	nl as nlPlurals,
	pl as plPlurals,
	pt as ptPlurals,
	sv as svPlurals,
} from 'make-plural/plurals'

import { messages as enMessages } from '../locales/en/messages'

import dayjs from './dayjs'
import momentLocaleSelector from '../resources/moment/localeSelector'
import dayjsLocaleSelector from '../resources/dayjs/localeSelector'

import e_AutoTranslateLanguage from 'enums/e_AutoTranslateLanguage'
import e_DateFormat from 'enums/e_DateFormat'
import e_Currency from 'enums/e_Currency'
import e_NumberFormatLocale from 'enums/e_NumberFormatLocale'
import e_CalendarLanguage from 'enums/e_CalendarLanguage'

// Load plural rules
// Note: Must be kept in sync with e_AutoTranslateLanguage enum
i18n.loadLocaleData({
	en: { plurals: enPlurals },
	no: { plurals: noPlurals },

	da: { plurals: daPlurals },
	de: { plurals: dePlurals },
	es: { plurals: esPlurals },
	fr: { plurals: frPlurals },
	hy: { plurals: hyPlurals },
	it: { plurals: itPlurals },
	lb: { plurals: lbPlurals },
	nl: { plurals: nlPlurals },
	pl: { plurals: plPlurals },
	pt: { plurals: ptPlurals },
	sv: { plurals: svPlurals },
})

// Default locale using English as fallback message strings (for now) :)
const DEFAULT_I18N_LANGUAGE = e_AutoTranslateLanguage.ENGLISH

i18n.load(DEFAULT_I18N_LANGUAGE, enMessages)
i18n.activate(DEFAULT_I18N_LANGUAGE)

const loadedMomentLocaleByBaseLanguage = {}

const setMomentLocale = (calendarLocale) => {
	if (loadedMomentLocaleByBaseLanguage[calendarLocale]) return moment.locale(`override_${calendarLocale}`)

	if (!calendarLocale) return moment.locale('en')

	if (calendarLocale === e_CalendarLanguage.EN_US) return moment.locale('en')

	const calendarLocaleConfig = momentLocaleSelector(calendarLocale)

	if (calendarLocaleConfig) {
		moment.locale(`override_${calendarLocale}`, calendarLocaleConfig)
		loadedMomentLocaleByBaseLanguage[calendarLocale] = calendarLocaleConfig
	} else {
		moment.locale('en')
	}
}

const loadedLocales = {}
const setDayjsLocale = (calendarLocale) => {
	if (!loadedLocales[calendarLocale]) {
		const locale = dayjsLocaleSelector(calendarLocale)
		if (locale) {
			dayjs.locale(locale, null, true)
			loadedLocales[calendarLocale] = true
		}
	}
	dayjs.locale(calendarLocale)
}

const getHighchartsConfigFromMomentLocale = (calendarLocale) => {
	let momentConfig = momentLocaleSelector(calendarLocale)

	// Our `momentLocaleSelector` currently does not provide the default English
	// locale definition, so grab it from moment itself when necessary
	if (!momentConfig) {
		momentConfig = moment.localeData('en')._config
	}

	// Moment uses arrays of strings in most month/week definitions, but some
	// languages have them defined as functions or objects to allow more granual
	// localizations based on context. For us, notably Polish and Armenian.
	//
	// This conversion function ensures Highcharts receives what it expects.
	//
	// Example function-based: client/src/resources/moment/locale-pl.js#L31
	// Example object-based: client/src/resources/moment/locale-hy-am.js#L6
	const constantize = (entry) => {
		if (typeof entry === 'function') {
			return entry()
		}
		if (typeof entry === 'object' && entry.standalone) {
			return entry.standalone
		}
		return entry
	}

	if (momentConfig) {
		return {
			months: constantize(momentConfig.months),
			shortMonths: constantize(momentConfig.monthsShort),
			weekdays: constantize(momentConfig.weekdays),
			shortWeekdays: constantize(momentConfig.weekdaysShort),
		}
	}
}

class LocaleController {
	constructor() {
		this.defaultBaseLanguage = e_AutoTranslateLanguage.ENGLISH
		this.defaultCalendarLocale = 'en'

		this.defaultDateTimeFormat = e_DateFormat.SHORT_DATE
		this.defaultNumberFormat = e_NumberFormatLocale.NB
		this.defaultCurrency = e_Currency.NOK

		this.baseLanguage = 'en'
		this.dateTimeFormat = e_DateFormat.SHORT_DATE
		this.numberFormat = e_NumberFormatLocale.NB
		this.currency = e_Currency.NOK
	}

	async setToDefault() {
		if (this.defaultCalendarLocale) {
			setMomentLocale(this.defaultCalendarLocale)
			setDayjsLocale(this.defaultCalendarLocale)
			luxonSettings.defaultLocale = this.defaultCalendarLocale

			this.dateTimeFormat = this.defaultDateTimeFormat
			this.numberFormat = this.defaultNumberFormat
			this.currency = this.defaultCurrency
		} else {
			moment.locale('en')
			setDayjsLocale('en')
			luxonSettings.defaultLocale = 'en'

			this.dateTimeFormat = e_DateFormat.SHORT_DATE
			this.numberFormat = e_NumberFormatLocale.NB
			this.currency = e_Currency.NOK
		}

		await this.setI18nLocale(this.defaultBaseLanguage)
		this.setLangAttribute()
	}

	async configure({ baseLanguage, calendarLanguage, dateTimeFormat, numberFormat, currency, setDefault }) {
		if (setDefault) {
			this.defaultBaseLanguage = baseLanguage || e_AutoTranslateLanguage.ENGLISH
			this.defaultCalendarLocale = calendarLanguage || 'en'
			this.defaultDateTimeFormat = dateTimeFormat || e_DateFormat.SHORT_DATE
			this.defaultNumberFormat = numberFormat || e_NumberFormatLocale.NB
			this.defaultCurrency = currency || e_Currency.NOK
		}

		if (this.baseLanguage !== baseLanguage) {
			await this.setI18nLocale(baseLanguage)
		}

		this.baseLanguage = baseLanguage || e_AutoTranslateLanguage.ENGLISH
		this.dateTimeFormat = dateTimeFormat || e_DateFormat.SHORT_DATE
		this.numberFormat = numberFormat || e_NumberFormatLocale.NB
		this.currency = currency || e_Currency.NOK

		const calendarLocale = calendarLanguage || 'en'
		setMomentLocale(calendarLocale)
		setDayjsLocale(calendarLocale)
		luxonSettings.defaultLocale = calendarLocale

		this.setLangAttribute()
	}

	getDateTimeFormat() {
		return this.dateTimeFormat
	}

	getNumberFormat() {
		return this.numberFormat
	}

	getCurrency() {
		return this.currency
	}

	getIsTranslated() {
		return this.defaultBaseLanguage !== this.baseLanguage
	}

	getHighchartsLocaleConfig() {
		const calendarLocale = luxonSettings.defaultLocale
		return {
			...getHighchartsConfigFromMomentLocale(calendarLocale),
			resetZoom: t`Reset Zoom`,
		}
	}

	async setI18nLocale(locale) {
		if (locale === i18n.locale) return

		if (locale !== DEFAULT_I18N_LANGUAGE) {
			const { messages } = await import(`../locales/${locale}/messages`)
			i18n.load(locale, messages)
		}
		i18n.activate(locale)
	}

	// Compliance with WCAG accessibility guidelines
	// See: https://www.w3.org/WAI/WCAG21/Techniques/html/H57
	setLangAttribute() {
		if ('document' in global) {
			const html = global.document.querySelector('html')
			if (html) {
				html.setAttribute('lang', this.baseLanguage)
			}
		}
	}
}

export default LocaleController
