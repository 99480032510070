/* eslint-disable no-unneeded-ternary */

import isString from 'lodash/isString'
import isNil from 'lodash/isNil'
import { ASCENDING } from 'enums/e_SortOrder'

const getSortValue = (object, additionalSortDataDict, sortDescriptor) => {
	if (sortDescriptor?.sortField?.edgeDataBinding && additionalSortDataDict)
		return additionalSortDataDict[object._id][sortDescriptor.sortNodeName]
	return object[sortDescriptor.sortNodeName]
}

const dataSourceObjectSorter = (objectArray, sortDescriptorArray, additionalSortDataDict) => {
	if (!objectArray) return { result: objectArray, didSort: false }
	if (!sortDescriptorArray) return { result: objectArray, didSort: false }
	if (!sortDescriptorArray.length) return { result: objectArray, didSort: false }

	let didSort = false
	const result = sortDescriptorArray.reduceRight(
		(sortedObjects, sortDescriptor) => {
			const sortValueNegative = sortDescriptor.sortOrder === ASCENDING ? -1 : 1
			const sortValuePositive = sortDescriptor.sortOrder === ASCENDING ? 1 : -1

			const positiveSortFlag = sortDescriptor.sortOrder === ASCENDING ? false : true
			const negativeSortFlag = sortDescriptor.sortOrder === ASCENDING ? true : false

			sortedObjects.sort((a, b) => {
				let valueA = getSortValue(a, additionalSortDataDict, sortDescriptor)
				let valueB = getSortValue(b, additionalSortDataDict, sortDescriptor)

				// nil -> null or undefined
				if (isNil(valueA)) {
					if (positiveSortFlag) didSort = true
					return sortValuePositive
				}
				if (isNil(valueB)) {
					if (negativeSortFlag) didSort = true
					return sortValueNegative
				}

				if (isString(valueA)) valueA = valueA.toUpperCase()
				if (isString(valueB)) valueB = valueB.toUpperCase()

				if (valueA < valueB) {
					if (negativeSortFlag) didSort = true
					return sortValueNegative
				}

				if (valueA > valueB) {
					if (positiveSortFlag) didSort = true
					return sortValuePositive
				}

				return 0
			})

			return sortedObjects
		},
		[...objectArray]
	)

	return { result, didSort }
}

export default dataSourceObjectSorter
