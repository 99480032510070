import ObjectId from 'bson-objectid'
import axios from 'axios'

/* import { LOCATION } from 'enums/e_SystemPermissionType' */
import { p_enableNotification, p_registerPushSubscription } from 'utils/pwaUtils'

const WEB_PUSH_PUBLIC_KEY = window.AF_PARAMS.webPushPublicKey

const requestNotifications = (appController) => {
	const activeApp = appController.getActiveAppMetadata()
	const userId = appController.getCurrentUserId()

	if (!activeApp) return
	if (!userId) return

	const controller = new AbortController()

	p_enableNotification()
		.then(() => {
			return p_registerPushSubscription(WEB_PUSH_PUBLIC_KEY)
		})
		.then((sub) => {
			if (!localStorage) throw new Error('LocalStorage is not available - no need to register push')

			const itemKey = `__AF_WEB_PUSH_SUB__${userId}`

			let subId = localStorage.getItem(itemKey)

			if (!subId) {
				subId = new ObjectId().toString()
				localStorage.setItem(itemKey, subId)
			}

			return axios.post(
				`/api/v1/apps/${activeApp.id}/webpush/${subId}`,
				{ subscription: sub },
				{ signal: controller.signal }
			)
		})

		.then(() => console.log('Web Push Subscription update ok '))
		.catch((err) => console.error('Unable to enable push messages: ', err))

	return () => controller.abort()
}

const p_requestSystemPermission = ({ actionNode, appController, actionNodeLogger }) => {
	switch (actionNode.permissionType) {
		/* case LOCATION:
			// trigger system prompt for location
			break */

		default:
			//NOTIFICATIONS
			actionNodeLogger.debug('Request system permission for Notifications')
			requestNotifications(appController)
	}

	return Promise.resolve()
}

export default p_requestSystemPermission
