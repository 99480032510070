import ActionRunner from '../ActionRunner'
import { injectActionParamsInContextData } from 'utils/contextDataUtils'

const p_runAction = ({
	actionNode,
	contextData,
	appController,
	dispatch,
	getState,
	actionNodeRunner,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		const actionId = actionNode.actionId

		if (!actionId) reject(new Error('runActionRoot: No action id is provided'))

		const actions = getState().metaData.actions
		const actionForRun = actions[actionId]

		if (!actionForRun) reject(new Error('runActionRoot: Could not find action instance'))

		if (appController.getIsActionRunning(actionId)) {
			return actionNodeLogger.warning(
				'Cannot run action - Another instance of this action is already running'
			)
		}

		const eventContext = actionNodeRunner.getEventContext()
		if (actionForRun.actionParams?.length) {
			contextData = injectActionParamsInContextData({
				actionParams: actionForRun.actionParams,
				actionInputParams: actionNode.actionInputParams,
				contextData,
				eventContext,
				getDataFromDataValue: appController.getDataFromDataValue,
			})
		}

		const actionRunner = new ActionRunner(
			actionForRun,
			contextData,
			dispatch,
			getState,
			eventContext,
			actionNodeLogger
		)

		const actionDebugger = actionNodeRunner.rootActionRunner.actionDebugger
		if (actionDebugger) {
			actionRunner.attachDebugger(actionDebugger)
		}
		actionRunner
			.run()
			.then(() => resolve())
			.catch((err) => reject(err))
	})

export default p_runAction
