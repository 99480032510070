import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'

import { makeEvaluateFilter } from 'selectors/filterSelectors'
import { generateFilterFromGroupNode } from 'utils/filterGenerator'
import { makeGetSortedObjects } from 'selectors/sortingSelectors'

const styles = (theme) => ({
	root: {
		position: 'relative',
	},
	clickable: {
		cursor: 'pointer',
		transition: theme.transitions.create(['box-shadow', 'transform'], {
			duration: theme.transitions.duration.shortest,
		}),
		'&:hover': {
			zIndex: '100 !important',
			boxShadow: theme.shadows[4],
			transform: 'scale(1.02)',
		},
	},
	lineBreak: { whiteSpace: 'pre-line' },
})

class UiAvatarGroup extends Component {
	constructor(props) {
		super(props)

		this.state = {
			filterFunction: makeEvaluateFilter(),
			getSortedObjects: makeGetSortedObjects(),
		}

		this.onCountClick = this.onCountClick.bind(this)
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let ownData = nextProps.ownData
		const filterFunction = prevState.filterFunction
		const getSortedObjects = prevState.getSortedObjects
		const appController = nextProps.appController

		if (!ownData) return null

		if (nextProps.component.filterDescriptor) {
			if (nextProps.component.filterDescriptor.staticFilter) {
				ownData = filterFunction(nextProps.ownData, nextProps.component.filterDescriptor.staticFilter)
			} else if (nextProps.component.filterDescriptor) {
				const filter = generateFilterFromGroupNode({
					filterDescriptorNode: nextProps.component.filterDescriptor,
					contextData: nextProps.contextData,
					appController: appController,
				})
				ownData = filterFunction(nextProps.ownData, filter)
			}
		}

		if (nextProps.component.conditionalFilter && nextProps.component.conditionalFilter.length) {
			nextProps.component.conditionalFilter
				.filter((item) => appController.getDataFromDataValue(item.condition, nextProps.contextData))
				.forEach((item) => {
					if (item.staticFilter) {
						ownData = filterFunction(ownData, item.staticFilter)
					} else if (item.filterDescriptor) {
						const filter = generateFilterFromGroupNode({
							filterDescriptorNode: item.filterDescriptor,
							contextData: nextProps.contextData,
							appController: appController,
						})
						ownData = filterFunction(ownData, filter)
					}
				})
		}

		if (nextProps.component.sorting && nextProps.component.sorting.length) {
			let sorting = nextProps.component.sorting.filter((sortItem) => {
				if (isNil(sortItem.enabled)) return true

				return appController.getDataFromDataValue(sortItem.enabled, nextProps.contextData)
			})

			if (sorting.length === nextProps.component.sorting.length) sorting = nextProps.component.sorting

			ownData = getSortedObjects(ownData, sorting)
		}

		const dataSourceId = nextProps.component.value && nextProps.component.value.dataSourceId

		ownData = ownData.map((dataObject) => {
			const itemContext = {
				...nextProps.contextData,
				[dataSourceId]: [dataObject],
			}

			const handleClick = nextProps.component.onClick
				? (event) => {
					nextProps.eventHandler(nextProps.component.onClick, itemContext, { eventType: 'onClick' }, event)
				  }
				: undefined

			return {
				...dataObject,
				__imageUrl: nextProps.appController.getDataFromDataValue(
					nextProps.component.avatarImage,
					itemContext
				),
				__tooltip: nextProps.appController.getDataFromDataValue(
					nextProps.component.tooltip?.title,
					itemContext
				),
				__onClick: handleClick,
			}
		})

		const avatarStyle = nextProps.component.size
			? {
				height: nextProps.component.size,
				width: nextProps.component.size,
				fontSize: `${nextProps.component.size / 2}px`,
			  }
			: undefined

		return {
			...prevState,
			ownData,
			avatarStyle,
		}
	}

	onCountClick(event) {
		this.props.eventHandler(
			this.props.component.onCountClick,
			this.props.contextData,
			{ eventType: 'onClick' },
			event
		)
	}

	render() {
		const { component, styleProp, conditionalClassNames, classes } = this.props
		const { ownData, avatarStyle } = this.state
		const { visibleCount = 4, spacing, variant } = component

		if (!ownData) return null

		const hiddenAvatarsExists = ownData.length > visibleCount
		const visibleData = hiddenAvatarsExists ? ownData.slice(0, visibleCount - 1) : ownData
		return (
			<AvatarGroup
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
				style={styleProp}
				spacing={spacing}
				max={ownData.length}
			>
				{ visibleData.map((dataObject) => {
					if (dataObject.__tooltip) {
						return (
							<Tooltip
								key={dataObject._id}
								title={dataObject.__tooltip}
								interactive={!component.tooltip.disableInteractive}
								classes={component.tooltip.lineBreak ? { tooltip: classes.lineBreak } : undefined}
							>
								<Avatar
									src={dataObject.__imageUrl}
									alt={dataObject.__tooltip}
									onClick={dataObject.__onClick}
									className={classNames({ [classes.clickable]: !!dataObject.__onClick })}
									variant={variant}
									style={avatarStyle}
								/>
							</Tooltip>
						)
					}
					return (
						<Avatar
							key={dataObject._id}
							src={dataObject.__imageUrl}
							onClick={dataObject.__onClick}
							className={classNames({ [classes.clickable]: !!dataObject.__onClick })}
							variant={variant}
							style={avatarStyle}
						/>
					)
				}) }
				{ hiddenAvatarsExists &&
					(component.tooltip ? (
						<Tooltip
							title={ownData
								.slice(visibleCount, ownData.length - 1)
								.map((item) => item.__tooltip)
								.join(', ')}
							interactive={!component.tooltip.disableInteractive}
							classes={component.tooltip.lineBreak ? { tooltip: classes.lineBreak } : undefined}
						>
							<Avatar
								onClick={this.props.component.onCountClick ? this.onCountClick : undefined}
								className={classNames({ [classes.clickable]: !!this.props.component.onCountClick })}
								variant={variant}
								style={avatarStyle}
							>
								{ `+${ownData.length - visibleCount + 1}` }
							</Avatar>
						</Tooltip>
					) : (
						<Avatar
							onClick={this.props.component.onCountClick ? this.onCountClick : undefined}
							className={classNames({ [classes.clickable]: !!this.props.component.onCountClick })}
							variant={variant}
							style={avatarStyle}
						>
							{ `+${ownData.length - visibleCount + 1}` }
						</Avatar>
					)) }
			</AvatarGroup>
		)
	}
}

UiAvatarGroup.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	ownData: PropTypes.array,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiAvatarGroup)
