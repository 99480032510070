import ActionNodeRunner from './ActionNodeRunner'
import appController from '../../controllers/appControllerInstance'
import { actionLogger as logger } from '@logger'
import executeBlockInSequence from '../actionNodes/helpers/executeBlockInSequence'
import isUndefined from 'lodash/isUndefined'
import { makeIsActionNotMuted } from 'utils/logger/utils'
import e_ExecutionMode from 'enums/e_ExecutionMode'

class ActionRunner {
	constructor(actionInstance, contextData, dispatch, getState, eventContext, parentLogger) {
		this.id = actionInstance.id
		this.actionInstance = actionInstance
		this.contextData = contextData
		this.dispatch = dispatch
		this.eventContext = eventContext

		const currentLogger = parentLogger || logger
		this.actionLogger = currentLogger.createChildLogger(
			{ prefix: `Action: ${this.actionInstance.name}` },
			makeIsActionNotMuted(actionInstance.id)
		)

		// Debugger
		this.actionDebugger = false

		this.actionNodes = actionInstance.actionNodes.map(
			(actionNode, index) =>
				new ActionNodeRunner(
					{ root: this, parent: this },
					index,
					actionNode,
					dispatch,
					getState,
					this.actionLogger
				)
		)

		this.attachDebugger = this.attachDebugger.bind(this)
		this._debug_executionRequest = this._debug_executionRequest.bind(this)
	}

	run() {
		return new Promise((resolve, reject) => {
			if (!this.actionNodes.length) {
				this.actionLogger.error('Could not run Action: No Action Nodes in Action')
				return resolve()
			}
			console.groupEnd()
			// validate actionparams
			const requiredActionParams = this.actionInstance.actionParams?.filter(
				(actionParam) => actionParam.required
			)
			if (requiredActionParams?.length) {
				const actionParamData = this.contextData.action_params || {}

				const missingActionParams = requiredActionParams.reduce((missingList, actionParam) => {
					if (isUndefined(actionParamData[actionParam.id])) missingList.push(actionParam.name)
					return missingList
				}, [])

				if (missingActionParams?.length) {
					this.actionLogger.error(
						'Could not run action - Missing value for required action param(s) ' +
							missingActionParams.join(', ')
					)

					return reject(
						new Error(
							'Could not run action - Missing value for required action param(s) ' +
								missingActionParams.join(', ')
						)
					)
				}
			}
			console.group('Action: ', this.actionInstance.name)
			this.actionLogger.debug(`Run Action: ${this.actionInstance.name}`)
			this.actionLogger.time(this.actionInstance.name)

			if (!this.actionInstance.allowParallel) appController.setRunningAction(this.actionInstance, this)

			if (this.actionDebugger) {
				this.actionDebugger.actionApi_startAction(this.id)
			}
			this.actionLogger.context(this.contextData)

			executeBlockInSequence(this.actionNodes, this.contextData)
				.then(() => {
					console.groupEnd()
					this.actionLogger.timeEnd(this.actionInstance.name)
					if (!this.actionInstance.allowParallel) appController.actionDone(this.actionInstance.id)
					if (this.actionDebugger) {
						this.actionDebugger.actionApi_actionSuccess(this.id)
					}
					resolve()
				})
				.catch((err) => {
					console.groupEnd()
					this.actionLogger.error(`Action failed: ${this.actionInstance.name}`)
					this.actionLogger.timeEnd(this.actionInstance.name)
					if (!this.actionInstance.allowParallel) appController.actionDone(this.actionInstance.id)

					if (this.actionDebugger) {
						this.actionDebugger.actionApi_actionFailed(this.id, err)
					}
					reject(err)
				})
		})
	}

	attachDebugger(actionDebugger) {
		this.actionDebugger = actionDebugger
	}

	/**
	 * Internal Debug API
	 */
	_debug_executionRequest(actionNodeId, actionNodeName) {
		return new Promise((resolve, reject) => {
			this.actionDebugger
				.actionApi_requestPermissionForExecute(this.id, actionNodeId, {
					actionLogger: this.actionLogger,
					actionName: this.actionInstance.name,
					actionNodeName,
				})
				.then(() => resolve())
				.catch((err) => reject(err))
		})
	}

	_debug_getExecutionModeOverride(actualExecutionMode) {
		const runSequential = this.actionDebugger.actionApi_breakpointsExists(this.id)
		if (runSequential && actualExecutionMode && actualExecutionMode !== e_ExecutionMode.SEQUENTIAL)
			this.actionLogger.debug(`Changing execution mode from "${actualExecutionMode}" to "sequential".`)

		return runSequential ? e_ExecutionMode.SEQUENTIAL : actualExecutionMode
	}

	_debug_setContextData(actionNodeId, contextData) {
		this.actionDebugger.actionApi_setContextData(this.id, actionNodeId, contextData)
	}

	_debug_actionNodeSuccess(actionNodeId) {
		this.actionDebugger.actionApi_notifyExecutionSuccess(this.id, actionNodeId)
	}

	_debug_actionNodeFailed(actionNodeId, error) {
		this.actionDebugger.actionApi_notifyExecutionFailed(this.id, actionNodeId, error)
	}
}

export default ActionRunner
